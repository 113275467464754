import React, { useEffect, useState } from 'react';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import './scss/style.scss'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux'
import { CSpinner } from '@coreui/react'
import axios from 'axios';
import allActions from './actions/allActions'
import cookie from "js-cookie";
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))

const App = () => {

  const token = cookie.get("token");
  const currentUser = useSelector(state => state.currentUser)
  const dispatch = useDispatch();
  const [requestedUrl, setRequestedUrl] = useState("");

  useEffect(() => {
    const vaildateUser = async () => {
      // verify a token symmetric
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      await axios.get(`${process.env.REACT_APP_API_BASE_URL}/auth/user-profile`)
        .then((res) => {
          dispatch(allActions.userActions.setUser(res.data))

        }).catch((err) => {
          axios.defaults.headers.common["Authorization"] = ``;
          localStorage.clear();
          cookie.remove("token");
          dispatch(allActions.userActions.logOut())

        });
    }
    if (token) {
      vaildateUser();
    }
    else {
      localStorage.clear();
    }
  }, [token]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!requestedUrl) {
      setRequestedUrl(window.location.hash.substring(1))
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <HashRouter>
      <React.Suspense fallback={loading}>
        <ToastContainer />
        <Switch>
          {
            currentUser.loggedIn === true
              ?
              <>
                <Route path="/" name="Home" render={(props) => <DefaultLayout {...props} />} />
              </>
              :
              token ?
                <>
                  <div className={"d-flex justify-content-center align-items-center h-100"} >
                    <CSpinner color="primary" />
                  </div>
                </>
                :
                <>
                  <Route exact path="/login" name="Login Page" render={(props) => <Login {...props} />} />
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                </>

          }
          <Route component={Page404} />
        </Switch>
      </React.Suspense>
    </HashRouter>
  )
}

export default App
